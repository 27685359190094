<template>
	<div id="admin" class="frame">
		Admin 预留入口
	</div>
</template>

<script>
export default {
    name: 'frame-admin'
};
</script>